


const rowMatchCount = (selectedEntry, destinationEntry, dungeonGrid) => {
    const name = selectedEntry.name

    const rowIndex = destinationEntry.row
    let validCount = 0

    const row = dungeonGrid[rowIndex]
    const columnIndex = destinationEntry.column

    if(destinationEntry.row === selectedEntry.row) {
      //direction matters  since two neighbors are swapping position and are different from each other
      if(destinationEntry.column > selectedEntry.column) {
        //moving right; so check right
        validCount = validateRight(row, columnIndex,name, validCount)
      } else {
        //need to check to the left
        validCount = validateLeft(row,columnIndex, name, validCount)
      }
    
    }
    else {
      //check both directions since selected is moving into row so both directions matter
      validCount = validateRight(row, columnIndex, name, validCount)
     validCount = validateLeft(row, columnIndex, name, validCount)
    }
    console.log("Row Match: "+ validCount)
    return validCount
  }

  const walkRightTillNotMatching = (tile, name,currentCount) => {

    if(tile.right.name === name) {
     return walkRightTillNotMatching(tile.right, name, currentCount+1) 
    }
    return currentCount 
  }
  const walkDownTillNotMatching = (tile, name,currentCount) => {

    if(tile.down.name === name) {
     return walkDownTillNotMatching(tile.down, name, currentCount+1) 
    }
    return currentCount 
  }
  const walkUpTillNotMatching = (tile, name,currentCount) => {

    if(tile.up.name === name) {
     return walkUpTillNotMatching(tile.up, name, currentCount+1) 
    }
    return currentCount 
  }
  const walkLeftTillNotMatching = (tile, name,currentCount) => {

    if(tile.left.name === name) {
     return walkLeftTillNotMatching(tile.left, name, currentCount+1) 
    }
    return currentCount 
  }

  const validateRight = (row,columnIndex,  name, validCount) => {

    let tile = row[columnIndex]
    
    const foundCount = walkRightTillNotMatching(tile, name, validCount)
    return foundCount
  }


  const validateLeft = (row, columnIndex,name, validCount) => {

    let tile = row[columnIndex]
    
    const foundCount = walkLeftTillNotMatching(tile, name, validCount)
    return foundCount 

  }
  const columnMatchCount = (selectedEntry, destinationEntry, dungeonGrid) => {

    const name = selectedEntry.name

    const rowIndex = destinationEntry.row
    const columnIndex = destinationEntry.column
    let validCount = 0

    if(destinationEntry.column === selectedEntry.column) {
      //direction matters  since two neighbors are swapping position and are different from each other
      if(destinationEntry.row < selectedEntry.row) {
        //moving up; so check above
        validCount = validateAbove(rowIndex, columnIndex,name, validCount,dungeonGrid)
      } else {
        //need to check to the left
        validCount = validateBelow(rowIndex, columnIndex,name, validCount, dungeonGrid)
      }
    
    }
    else {
      //check both directions since selected is moving into row so both directions matter
      validCount = validateAbove(rowIndex, columnIndex,name, validCount, dungeonGrid)
      validCount = validateBelow(rowIndex, columnIndex,name, validCount, dungeonGrid)
    //  validCount = validateLeft(row, columnIndex, name, validCount)
    }
    console.log("Column Match: "+ validCount)
    return validCount

  }


  const validateBelow = (rowIndex, columnIndex,name, validCount, dungeonGrid) => {
    
    const tile = dungeonGrid[rowIndex][columnIndex]
    const foundCount = walkDownTillNotMatching(tile, name, validCount)
    return foundCount 
  }
const validateAbove = (rowIndex, columnIndex,name, validCount, dungeonGrid) => {

  const tile = dungeonGrid[rowIndex][columnIndex]
    const foundCount = walkUpTillNotMatching(tile, name, validCount)
    return foundCount 
}
const validMove = (selectedEntry, destinationEntry) => {

    const selectedRow = selectedEntry.row
    const selectedCol = selectedEntry.column

    if(selectedEntry.name !== destinationEntry.name ) {
      if( selectedRow === destinationEntry.row) {
        if ( (selectedCol === destinationEntry.column+1) || (selectedCol === destinationEntry.column - 1) ) {
          return true
        }
      } else if( selectedCol === destinationEntry.column) {
        if ( (selectedRow === destinationEntry.row+1) || (selectedRow === destinationEntry.row - 1) ) {
          return true
        }
      } 
    }
    return false

}

export {columnMatchCount, rowMatchCount, validMove}