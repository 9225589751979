import React from 'react'

export default function GameBanner() {

  return (
    <div className='header'>
        <h2> DungeonCrush </h2>
        </div>
  )
}
