import { useEffect, useState } from "react";
import '../../App.css'
import Tile, { swapPositions } from "../tile/Tile";
import { dungeonEmpty } from '../../Constants.js'
import { createDungeon, refillEmptyTiles, shiftTilesDown } from "../logic/logic";
import { columnMatchCount, rowMatchCount, validMove } from "../logic/validation"
import { useMoves, useMovesUpdate, usePreviousScoreUpdate, useScore, useScoreUpdate } from "../scoreboard/ScoreContext";
import { useCurrentDungeon, useCurrentDungeonGrid, useCurrentDungeonUpdate, useCurrentDungeonGridUpdate, useColumnRefill, useRowRefill, useColumnRefillUpdate, useRowRefillUpdate, useCurrentWidth, useCurrentHeight, useCurrentWidthUpdate, useCurrentHeightUpdate } from "./DungeonContext";
import { updateGrid, getLengthOfMatch, findAllTouchingTiles } from "../logic/Matching";
const Dungeon = () => {

  const currentWidth = useCurrentWidth()
  const currentHeight = useCurrentHeight()
  const setCurrentWidth = useCurrentWidthUpdate()
  const setCurrentHeight = useCurrentHeightUpdate()
  const currentDungeon = useCurrentDungeon()
  const setCurrentDungeon = useCurrentDungeonUpdate()
  const currentDungeonGrid = useCurrentDungeonGrid();
  const setCurrentDungeonGrid = useCurrentDungeonGridUpdate()
  const rowRefill = useRowRefill()
  const columnRefill = useColumnRefill()
  const setRowRefill = useRowRefillUpdate()
  const setColumnRefill = useColumnRefillUpdate()

  const [selectedEntry, setSelectedEntry] = useState(null);
  const [destinationEntry, setDestinationEntry] = useState(null);
  const [currentRowMatch, setRowMatch] = useState(0)
  const [currentColumnMatch, setColumnMatch] = useState(0)
  const [boardStatus, setBoardStatus] = useState({});



  const [boardSize, setBoardSize] = useState('Normal')


  const score = useScore()
  const setScore = useScoreUpdate()
  const moves = useMoves()
  const setMoves = useMovesUpdate()
  const setPreviousScore = usePreviousScoreUpdate()

  const createBoard = () => {

    const [dungeon, dungeonGrid] = createDungeon(currentWidth, currentHeight)
    setCurrentDungeon(dungeon)
    setCurrentDungeonGrid(dungeonGrid)
    setScore(0)
    setMoves(0)
    setPreviousScore(0)
  }
  const dragStart = (e) => {
    setSelectedEntry(currentDungeon[parseInt(e.target.getAttribute('data-id'))]);
  }
  const dragDrop = (e) => {
    setDestinationEntry(currentDungeon[parseInt(e.target.getAttribute('data-id'))]);
  }

  const dragEnd = () => {
    const rowMatch = rowMatchCount(selectedEntry, destinationEntry, currentDungeonGrid)
    const columnMatch = columnMatchCount(selectedEntry, destinationEntry, currentDungeonGrid)
    const valid = validMove(selectedEntry, destinationEntry)
    const rowMatchReverse = rowMatchCount(destinationEntry, selectedEntry, currentDungeonGrid)
    const columnMatchReverse = columnMatchCount(destinationEntry, selectedEntry, currentDungeonGrid)
    const validReverse = validMove(destinationEntry, selectedEntry)

    const newMatchCount = getLengthOfMatch(selectedEntry, destinationEntry, currentDungeonGrid)

    if (newMatchCount > 3) {
      console.log("new match count: " + newMatchCount)
    }
    const boardStatus = {}

    boardStatus.rowMatch = rowMatch
    boardStatus.columnMatch = columnMatch
    boardStatus.rowMatchReverse = rowMatchReverse
    boardStatus.columnMatchReverse = columnMatchReverse
    boardStatus.valid = valid
    boardStatus.validReverse = validReverse
    boardStatus.newMatchCount = newMatchCount

    if (valid || validReverse) {
      destinationEntry.selected = true
      selectedEntry.selected = true

      if (rowMatch >= 2 || columnMatch >= 2 || rowMatchReverse >= 2 || columnMatchReverse >= 2) {

        swapPositions(selectedEntry, destinationEntry)
        let foundTiles = [];
        if (valid) {
          const tiles = findAllTouchingTiles(destinationEntry.name, destinationEntry)
          if (tiles.length > 2) {
            foundTiles = foundTiles.concat(tiles)
          }
        }
        if (validReverse) {
          const tiles = findAllTouchingTiles(selectedEntry.name, selectedEntry)
          if (tiles.length > 2) {
            foundTiles = foundTiles.concat(tiles)
          }
        }
        foundTiles.forEach(tile => {
          tile.matched = true
        })

        boardStatus.matchingTiles = foundTiles
        swapPositions(selectedEntry, destinationEntry)
      }

    }
    setBoardStatus(boardStatus)
  }


  const handleValidMove = () => {
    const isEmpty = Object.keys(boardStatus).length === 0;
    if (!isEmpty) {
      let rowMatch = boardStatus.rowMatch;
      let columnMatch = boardStatus.columnMatch
      let rowMatchReverse = boardStatus.rowMatchReverse
      let columnMatchReverse = boardStatus.columnMatchReverse
      let valid = boardStatus.valid
      let validReverse = boardStatus.validReverse

      let moveScore = 0
      let multiplier = 1
      if (valid && validReverse && (rowMatch >= 2 || columnMatch >= 2) && (rowMatchReverse >= 2 || columnMatchReverse >= 2)) {
        multiplier = 2
        swapPositions(selectedEntry, destinationEntry)
        moveScore += updateGrid(destinationEntry, multiplier, setRowRefill)
        moveScore += updateGrid(selectedEntry, multiplier, setRowRefill)
        setEmpty(destinationEntry)
        setEmpty(selectedEntry)

        setScore(moveScore + score)
        setColumnMatch((columnMatch >= columnMatchReverse) ? columnMatch : columnMatchReverse)
        setRowMatch((rowMatch >= rowMatchReverse) ? rowMatch : rowMatchReverse)
        setMoves(moves + 1)

      }
      else if (valid && (rowMatch >= 2 || columnMatch >= 2)) {

        swapPositions(selectedEntry, destinationEntry)
        moveScore += updateGrid(destinationEntry, multiplier, setRowRefill)
        setEmpty(destinationEntry)
        setScore(moveScore + score)
        setColumnMatch(columnMatch)
        setRowMatch(rowMatch)
        setMoves(moves + 1)
      }
      else if (validReverse && (rowMatchReverse >= 2 || columnMatchReverse >= 2)) {
        swapPositions(selectedEntry, destinationEntry)
        moveScore += updateGrid(selectedEntry, multiplier, setRowRefill)

        setEmpty(selectedEntry)
        setScore(moveScore + score)
        setColumnMatch(columnMatchReverse)
        setRowMatch(rowMatchReverse)
        setMoves(moves + 1)
      }
      boardStatus.matchingTiles.forEach(tile => {
        tile.matched = false
      })
      setBoardStatus({})
      setDestinationEntry({})
      setSelectedEntry({})
      setCurrentDungeonGrid(currentDungeonGrid)
      setCurrentDungeon(currentDungeon)
    }

  }

  const setEmpty = (tile) => {
    tile.name = dungeonEmpty.name
    tile.color = dungeonEmpty.color
  }

  const shiftBoardTilesDown = () => {
    console.log("in update: " + currentRowMatch + ", " + currentColumnMatch)
    if (currentRowMatch >= 2 || currentColumnMatch >= 2) {
      console.log("ABout to shift stuff down")
      shiftTilesDown(currentDungeonGrid, currentWidth, currentHeight)
      setCurrentDungeonGrid(currentDungeonGrid)
      setCurrentDungeon(currentDungeon)
      setRowMatch(0)
      setColumnMatch(0)
    }

  }

  const refillBoard = () => {

    if (rowRefill || columnRefill) {

      refillEmptyTiles(currentDungeon)

      setColumnRefill(false)
      setRowRefill(false)
      setCurrentDungeonGrid(currentDungeonGrid)
      setCurrentDungeon(currentDungeon)
    }


  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleValidMove()
    }, 1000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardStatus])

  useEffect(() => {
    createBoard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHeight])

  useEffect(() => {

    createBoard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    const timeout = setTimeout(() => {
      shiftBoardTilesDown()
    }, 200)
    return () => clearTimeout(timeout)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRowMatch, currentColumnMatch])

  useEffect(() => {
    const timeout = setTimeout(() => {
      refillBoard()
    }, 500)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnRefill, rowRefill])

  const setEasy = () => {
    setBoardSize('Easy')
    setCurrentWidth(20)
    setCurrentHeight(20)
    createBoard();
  }
  const setNormal = () => {
    setBoardSize('Normal')
    setCurrentWidth(10)
    setCurrentHeight(10)
    createBoard();
  }
  let difficulty = boardSize === 'Easy' ? "dungeon-easy" : "dungeon-normal"
  return (

    <div className={"board " + difficulty}>
      <div className="settings"><button onClick={createBoard}>New Game</button>
        <RadioButton label='Easy' value={boardSize === 'Easy'} onChange={setEasy} />
        <RadioButton label='Normal' value={boardSize === 'Normal'} onChange={setNormal} /> </div>
      <div className="dungeon" >
        {currentDungeon.map((entry, index) => (

          <Tile key={index} index={index} tile={entry} dragStart={dragStart} dragDrop={dragDrop} dragEnd={dragEnd} />

        ))

        }
      </div>
    </div>
  )
}
const RadioButton = ({ label, value, onChange }) => {
  return (
    <label>
      <input type="radio" checked={value} onChange={onChange} />
      {label}
    </label>
  );
};
export default Dungeon
