import React, { useState,useEffect } from 'react'
import '../../App.css'
import { useHighScore, useHighScoreUpdate, useMaxScore, useMaxScoreUpdate, 
    useMoves, useScore, usePreviousScore, usePreviousScoreUpdate } from './ScoreContext'

function ScoreBoard() {

    
    const [lastScore, setLastScore] = useState(0)

    const score = useScore()
    const moves = useMoves();

    const highScore = useHighScore()
    const setHighScore = useHighScoreUpdate()

    const maxScore = useMaxScore()
    const setMaxScore = useMaxScoreUpdate()

    const previousScore = usePreviousScore();
    const setPreviousScore = usePreviousScoreUpdate();

    let averageScore =  0
    if(moves !== 0) {
        averageScore = Math.round((score/moves + Number.EPSILON) * 100) / 100
    }

    useEffect( ()=> {
        if(score >= highScore) {
            setHighScore(score)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score])

    useEffect( ()=> {
        let difference = score - previousScore
        if(difference > maxScore) {
            setMaxScore(difference)
        }
        setLastScore(difference)
        setPreviousScore(score)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score])

  return (
    <div className='score-board'>
        <div className='score'><h2>Score: {score}</h2> </div>
        <div className='moves'><h2>Moves: {moves} </h2></div>
        <div className='score'><h2>Last Move Scored: {lastScore}</h2></div>
        <div className='high-score'><h2>High Score: {highScore}</h2></div>
        <div className='averageScore'><h2>Average: {averageScore}</h2></div>
        <div className='maxscore'> <h2>Max Single Move Score: {maxScore} </h2></div>

    
    </div>
  )
}

export default ScoreBoard