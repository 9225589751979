
const width = 10;
const height = 10;
const mediumWidth = 12
const mediumHeight = 12
const largeWidth=16
const largeHeight=16
const dungeonEntries =[
  {"name": 'dragon', "color": 'red', 'img':'./dragon.png'},
  {"name": 'goblin', "color": 'orange', 'img':'./goblin.png'},
  {"name": 'elf', "color": 'yellow', 'img':'./elf.png'},
  {"name": 'dwarf', "color": 'green', 'img':'./green-sphere.png'},
  {"name": 'wizard', "color": 'blue', 'img':'./wizard.png'},
  {"name": 'commoner', "color": 'purple', 'img':'purple-sphere.png'},
  {"name": 'door', "color":'brown', 'img':'./door.png'},
  {"name": 'sword', "color":'gray', 'img':'./sword.png'}
]
const dungeonEmpty = {"name": "", "color":"gold", 'img':''}
const edge = "edge"

const validateNeighbors = (tile, width, height) => {

  const rIndex = tile.row
  const cIndex = tile.column

  const up = tile.up
  const down = tile.down
  const left = tile.left
  const right = tile.right

  let status = true

  if(rIndex === 0 && up !== edge) {
      console.error("Invalid UP Neighbor: " + rIndex + ", "+ cIndex )
      status = false;
  } 
  if(rIndex === height && down !== edge) {
      console.error("Invalid Down Neighbor: " + rIndex + ", "+ cIndex )
      status = false;
  }
  if(cIndex === width && right !== edge) {
      console.error("Invalid Right Neighbor: " + rIndex + ", "+ cIndex )
      status = false;
  } if(cIndex === 0 && left !== edge) {
      console.error("Invalid Right Neighbor: " + rIndex + ", "+ cIndex )
      status = false;
  } 

  if(up !== edge) {
    if(up.column !== cIndex) {
      console.error("Invalid Up Column for:" + rIndex + ", "+ cIndex)
      status = false;
    }
    if(up.row !== (rIndex-1)) {
      console.error("Invalid Up row for:" + rIndex + ", "+ cIndex)
      status = false;
    }
    if(up.down.column !== cIndex && up.down.row !== rIndex) {
      console.error("Improper Up's Down set: "+ rIndex + ", "+ cIndex)
      status = false;
    }
  }

  if(down !== edge) {
    if(down.column !== cIndex) {
      console.error("Invalid Down Column for:" + rIndex + ", "+ cIndex)
      status = false;
    }
    if(down.row !== (rIndex+1)) {
      console.error("Invalid down row for:" + rIndex + ", "+ cIndex)
      status = false;
    }
    if(down.up.column !== cIndex && down.up.row !== rIndex) {
      console.error("Improper DOWN's Up configured:" + rIndex + ", "+ cIndex)
      status = false;
    }
  }

  if(right !== edge) {
    if(right.row !== rIndex) {
      console.error("Improper Right's Row: "+ rIndex + ", "+ cIndex)
      status = false;
    }
    if(right.column !== (cIndex+1)) {
      console.error("Improper Right's Column: "+ rIndex + ", "+ cIndex)
      status = false;
    }
  }
  if(left !== edge) {
    if(left.row !== rIndex) {
      console.error("Improper Left's Row: "+ rIndex + ", "+ cIndex)
      status = false;
    }
    if(left.column !== (cIndex-1)) {
      console.error("Improper Left's Column: "+ rIndex + ", "+ cIndex)
      status = false;
    }
  }

  return status
}
export { width, height, mediumHeight, mediumWidth, largeHeight, largeWidth, dungeonEntries, dungeonEmpty, edge, validateNeighbors}