import React, { useContext, useState } from 'react'

const ScoreContext = React.createContext()
const HighScoreContext = React.createContext()
const ScoreUpdateContext = React.createContext()
const HighScoreUpdateContext = React.createContext()
const MovesContext = React.createContext()
const MovesUpdateContext = React.createContext()
const MaxScoreContext = React.createContext()
const MaxScoreUpdateContext = React.createContext()
const PreviousScoreContext = React.createContext()
const PreviousScoreUpdateContext = React.createContext()

export function useScore() {
    return useContext(ScoreContext)
}

export function useScoreUpdate() {
    return useContext(ScoreUpdateContext)
}

export function useHighScore() {
    return useContext(HighScoreContext)
}

export function useHighScoreUpdate() {
    return useContext(HighScoreUpdateContext)
}

export function useMoves() {
    return useContext(MovesContext)
}

export function useMovesUpdate() {
    return useContext(MovesUpdateContext)
}

export function useMaxScore() {
    return useContext(MaxScoreContext)
}

export function useMaxScoreUpdate() {
    return useContext(MaxScoreUpdateContext)
}

export function usePreviousScore() {
    return useContext(PreviousScoreContext)
}

export function usePreviousScoreUpdate() {
    return useContext(PreviousScoreUpdateContext)
}

export function ScoreProvider( { children }) {

    const [score, setScore] = useState(0)
    const [highScore, setHighScore] = useState(0)
    const [moves, setMoves] = useState(0)
    const [maxScore, setMaxScore] = useState(0)
    const [previousScore, setPreviousScore] = useState(0)

    
  return (
    <ScoreContext.Provider value={score}>
        <ScoreUpdateContext.Provider value={setScore}>
            <HighScoreContext.Provider value={highScore}>
                <HighScoreUpdateContext.Provider value={setHighScore}>
                    <MovesContext.Provider value={moves}>
                        <MovesUpdateContext.Provider value={setMoves}>
                            <MaxScoreContext.Provider value={maxScore}> 
                                <MaxScoreUpdateContext.Provider value={setMaxScore}>
                                    <PreviousScoreContext.Provider value={previousScore}>
                                        <PreviousScoreUpdateContext.Provider value={setPreviousScore}>
                                            {children}
                                        </PreviousScoreUpdateContext.Provider>
                                    </PreviousScoreContext.Provider>
                                </MaxScoreUpdateContext.Provider>
                            </MaxScoreContext.Provider>
                        </MovesUpdateContext.Provider>
                    </MovesContext.Provider>
                </HighScoreUpdateContext.Provider>
            </HighScoreContext.Provider>
        </ScoreUpdateContext.Provider>
    </ScoreContext.Provider>
  )
}


