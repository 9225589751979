import React, { useContext, useState } from 'react'
import { height, width } from '../../Constants'

const CurrentDungeonContext = React.createContext()
const CurrentDungeonUpdateContext = React.createContext()
const CurrentDungeonGridContext = React.createContext()
const CurrentDungeonGridUpdateContext = React.createContext()
const ColumnRefillContext = React.createContext()
const RowRefillContext = React.createContext()

const ColumnRefillUpdateContext = React.createContext()
const RowRefillUpdateContext = React.createContext()

const CurrentWidthContext = React.createContext()
const CurrentHeightContext = React.createContext()
const CurrentWidthUpdateContext = React.createContext()
const CurrentHeightUpdateContext = React.createContext()

export function useCurrentDungeon() {
    return useContext(CurrentDungeonContext)
}
export function useCurrentDungeonUpdate() {
    return useContext(CurrentDungeonUpdateContext)
}

export function useCurrentDungeonGrid() {
    return useContext(CurrentDungeonGridContext)
}
export function useCurrentDungeonGridUpdate() {
    return useContext(CurrentDungeonGridUpdateContext)
}

export function useColumnRefill() {
    return useContext(ColumnRefillContext)
}
export function useRowRefill() {
    return useContext(RowRefillContext)
}
export function useColumnRefillUpdate() {
    return useContext(ColumnRefillUpdateContext)
}
export function useRowRefillUpdate() {
    return useContext(RowRefillUpdateContext)
}

export function useCurrentWidth() {
    return useContext(CurrentWidthContext)
}

export function useCurrentHeight() {
    return useContext(CurrentHeightContext)
}
export function useCurrentWidthUpdate() {
    return useContext(CurrentWidthUpdateContext)
}

export function useCurrentHeightUpdate() {
    return useContext(CurrentHeightUpdateContext)
}

export function DungeonProvider( { children }) {

    const [columnRefill, setColumnRefill] = useState(false)
    const [rowRefill, setRowRefill] = useState(false)

    const [currentDungeon, setCurrentDungeon] = useState([])
    const [currentDungeonGrid, setCurrentDungeonGrid] = useState([]);

    const [currentWidth, setCurrentWidth] = useState(width)
    const [currentHeight, setCurrentHeight] = useState(height)

    return (
        <CurrentDungeonContext.Provider value={currentDungeon}>
            <CurrentDungeonUpdateContext.Provider value={setCurrentDungeon}>
                <CurrentDungeonGridContext.Provider value={currentDungeonGrid}>
                    <CurrentDungeonGridUpdateContext.Provider value={setCurrentDungeonGrid}>
                        <RowRefillContext.Provider value={rowRefill}>
                            <ColumnRefillContext.Provider value={columnRefill}>
                                <RowRefillUpdateContext.Provider value={setRowRefill}>
                                    <ColumnRefillUpdateContext.Provider value={setColumnRefill}>
                                        <CurrentWidthContext.Provider value={currentWidth}>
                                            <CurrentWidthUpdateContext.Provider value={setCurrentWidth}>
                                                <CurrentHeightContext.Provider value={currentHeight}>
                                                    <CurrentHeightUpdateContext.Provider value={setCurrentHeight}>

                                                    {children}            

                                                    </CurrentHeightUpdateContext.Provider>
                                                </CurrentHeightContext.Provider>
                                            </CurrentWidthUpdateContext.Provider>
                                        </CurrentWidthContext.Provider>
                                    </ColumnRefillUpdateContext.Provider>
                                </RowRefillUpdateContext.Provider>
                            </ColumnRefillContext.Provider>
                        </RowRefillContext.Provider>
                    </CurrentDungeonGridUpdateContext.Provider>
                </CurrentDungeonGridContext.Provider>
            </CurrentDungeonUpdateContext.Provider>
        </CurrentDungeonContext.Provider>
        
    )

}