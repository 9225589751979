 import { blankout, swapPositions } from "../tile/Tile"
 
 const getLengthOfMatch = (selectedEntry, destinationEntry, dungeonGrid) => {
    const name = selectedEntry.name
    const destinationName = destinationEntry.name
  
    // const row = dungeonGrid[rowIndex]
    swapPositions(selectedEntry,destinationEntry)
    var neighborsDestinationEntry = findAllTouchingTiles(name,destinationEntry)
    var neighborsSelectionEntry = findAllTouchingTiles(destinationName,selectedEntry)
    swapPositions(selectedEntry,destinationEntry)

    //only add lengths that are more than one tile... might set to length of 3 or more
    var destinationLength = neighborsDestinationEntry.length >1 ? neighborsDestinationEntry.length : 0
    var selectionLength = neighborsSelectionEntry.length > 1 ? neighborsSelectionEntry.length : 0
    return destinationLength + selectionLength
  
  }

 const findAllTouchingTiles = ( name, tile) => {
     let allFound = []
     let toBeSearched = []
     let searched = []

     toBeSearched.push(tile.left)
     toBeSearched.push(tile.right)
     toBeSearched.push(tile.up)
     toBeSearched.push(tile.down)
     searched.push(tile.id)
     allFound.push(tile)

     while(toBeSearched.length > 0) {

        let currentTile = toBeSearched.shift()
        searched.push(currentTile.id)
        if(currentTile.name === name) {
            allFound.push(currentTile)
            if(!searched.includes(currentTile.left.id) ) {
                toBeSearched.push(currentTile.left)
            }
            if(!searched.includes(currentTile.right.id) ) {
                toBeSearched.push(currentTile.right)
            }
            if(!searched.includes(currentTile.up.id)) {
                toBeSearched.push(currentTile.up)
            }
            if(!searched.includes(currentTile.down.id)) {
                toBeSearched.push(currentTile.down)
            }
        }

     }

     return allFound

 }

 const isHorizontalNeighborInList = (tile, ids) => {
    let inline = false
    let id
    if(tile.left !== 'edge') {
        id = tile.left.id
        inline = (inline || ids.includes(id))
    }
    if(tile.right !== 'edge') {
        id = tile.right.id
        inline = (inline || ids.includes(id))
    }
    return inline
 }
 const isVerticleNeighborInList = (tile, ids) => {
    let inline = false
    let id
    if(tile.up !== 'edge') {
        id = tile.up.id
        inline = (inline || ids.includes(id))
    }
    if(tile.down !== 'edge') {
        id = tile.down.id
        inline = (inline || ids.includes(id))
    }
    return inline
 }
 const isTileAlreadyInPath = (tile, paths) => {

    let found = false
    for(let i =0; i< paths.length; i++) {
        let p = paths[i]
        if(p.includes(tile.id)) {
            found = true
            break
        }

    }
    return found
 }
 const buildRowPath = (tile, ids) => {
     let neighbor = tile.left
     let path = []
     path.push(tile.id)
    
    while(neighbor !== 'edge' && ids.includes(neighbor.id)) {
        path.push(neighbor.id)
        neighbor = neighbor.left
    }


     neighbor = tile.right
    
    while(neighbor !== 'edge' && ids.includes(neighbor.id)) {
        path.push(neighbor.id)
        neighbor = neighbor.right
    }

    return path

 }
 const buildColumnPath = (tile, ids) => {
    let neighbor = tile.up
    let path = []
    path.push(tile.id)
   
   while(neighbor !== 'edge' && ids.includes(neighbor.id)) {
       path.push(neighbor.id)
       neighbor = neighbor.up
   }

    neighbor = tile.down
   
   while(neighbor !== 'edge' && ids.includes(neighbor.id)) {
       path.push(neighbor.id)
       neighbor = neighbor.down
   }

   return path

}
 const findDistinctPaths = (tiles) => {
    
    let ids = []
    tiles.forEach((tile)=> {
         ids.push(tile.id)
    })
    let pathList = []
    let columnPathList = []
    for(let i = 0; i < tiles.length; i++) {
        let tile = tiles[i]
        // is it in horizontal
        if(isHorizontalNeighborInList(tile, ids) && ! isTileAlreadyInPath(tile,pathList)) {
            pathList.push(buildRowPath(tile,ids))
        }
        if(isVerticleNeighborInList(tile, ids) && ! isTileAlreadyInPath(tile,columnPathList)) {
            columnPathList.push(buildColumnPath(tile,ids))
        }

    }


    return pathList.concat(columnPathList)
 }

 const updateGrid = ( destination, multiplier, setRefill) => {
    const name = destination.name
    let refillNeeded = false
    let points = 0;
    
      //points +=1 
      console.log(destination)

      const tilesToBeBlanked = findAllTouchingTiles(name,destination)
      const distintPaths = findDistinctPaths(tilesToBeBlanked)
      let modifiedMultiplier = multiplier + distintPaths.length  
      if(tilesToBeBlanked.length > 2) {
          refillNeeded = true
      }
      tilesToBeBlanked.forEach((tile) => {
          tile.selected = true
      })
      tilesToBeBlanked.forEach((tile)=> {
        points = (points>2)? points + 2*modifiedMultiplier : points + 1* modifiedMultiplier
        blankout(tile)

      })
    
    if(refillNeeded) {
        setRefill(true)
    }
    return points

 }

 

  export { updateGrid, 
    findAllTouchingTiles, findDistinctPaths,
     isHorizontalNeighborInList, isVerticleNeighborInList, buildRowPath, buildColumnPath, getLengthOfMatch }