
import { dungeonEntries,dungeonEmpty,edge} from '../../Constants.js'

const replacer = (key,value) => {
  if(key==="up") return undefined
  else if(key === "down") return undefined
  else if(key === "right") return undefined
  else if(key === "left") return undefined
  return value
}
const saveJSON = (dungeon)=> {
  return JSON.stringify(dungeon, replacer)
}

const createTile = (data, row, col) => {
    const entry = {}
    entry.id = row+ "," +col
    entry.name = data.name;
    entry.color = data.color;
    entry.img = data.img;
    entry.row = row;
    entry.column = col; 
    entry.selected = false ;
    entry.up = edge;
    entry.down = edge;
    entry.left = edge;
    entry.right = edge;
    return entry
}

const loadTile = (data) => {
  const entry = {}
    entry.id = data.id
    entry.name = data.name;
    entry.color = data.color;
    entry.img = data.img;
    entry.row = data.row;
    entry.column = data.column;
    entry.selected = false ;
    entry.up = edge;
    entry.down = edge;
    entry.left = edge;
    entry.right = edge;
    return entry
}
const setDirections = ( tile, previousRow, previousTile) => {

    if(previousRow.length !== 0) {
      tile.up = previousRow[tile.column]
      previousRow[tile.column].down = tile
    }
    if(previousTile!== null) {
      tile.left = previousTile
      previousTile.right = tile
    }

}

const generateTile = () => {
    return dungeonEntries[Math.floor(Math.random()*dungeonEntries.length)]
}

const loadDungeon = (data) =>{
  const dungeon = []
  const dungeonGrid = []
  let previousRow = []
  for(let row =0; row<data.length; row++) {
    const gridRow = []
    let previousTile = null
    const currentRow = data[row]
    for(let col =0; col <currentRow.length; col++) {
      let tile = loadTile(currentRow[col])
      setDirections(tile, previousRow,previousTile)
      previousTile = tile
      dungeon.push(tile)
      gridRow.push(tile)
    }
    previousRow = gridRow
    dungeonGrid.push(gridRow);

  }
  return [dungeon, dungeonGrid]
}

const createDungeon = (width, height) => {

    const dungeon = []
    const dungeonGrid = []
    let previousRow = []
    for(let row =0; row < height; row++) {
      const gridRow = []
      let previousTile = null
      for(let col = 0; col < width; col++) {
        const randomEntry = generateTile()
        let e = createTile(randomEntry, row, col)
        setDirections(e, previousRow, previousTile)
        previousTile = e
        dungeon.push(e)
        gridRow.push(e)
      
      }
      previousRow = gridRow
      dungeonGrid.push(gridRow);

    }

    return [dungeon,dungeonGrid]
}

const refillEmptyTiles = (dungeon) => {
  dungeon.filter( (tile) => tile.name === dungeonEmpty.name ).forEach( (tile)=> {
    const newTileContent = generateTile()
    tile.name = newTileContent.name
    tile.color = newTileContent.color
    tile.img = newTileContent.img
})
}

const shiftTilesDown = (dungeonGrid, width, height) => {
  for(let row =1; row < width; row++) {
    for(let col = 0; col < height; col++) {
      let tile = dungeonGrid[row][col]
      if(tile.name === dungeonEmpty.name) {
        while( tile.up.name !== edge.name) {
         tile.name = tile.up.name
         tile.color = tile.up.color
         tile.img = tile.up.img
         tile = tile.up
        }
        if(tile.up.name === edge.name) {
            tile.name = dungeonEmpty.name
            tile.color = dungeonEmpty.color
            tile.img = dungeonEmpty.img
        }

      }
    }
}
}

export {createDungeon, createTile, loadTile, generateTile, refillEmptyTiles, shiftTilesDown, saveJSON, loadDungeon, setDirections}