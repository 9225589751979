import React from "react";
import './App.css'
import GameBanner from "./components/banner/GameBanner";
import Dungeon from "./components/dungeon/Dungeon";
import { DungeonProvider } from "./components/dungeon/DungeonContext";
import ScoreBoard from "./components/scoreboard/ScoreBoard";
import {ScoreProvider} from "./components/scoreboard/ScoreContext"
const App = () => {
  
  return (

    <div className="game">

      <ScoreProvider>
        <DungeonProvider>
        <GameBanner/>
        <div className="content"> 
          <Dungeon/>
          <ScoreBoard/>
        </div>
        </DungeonProvider>
     </ScoreProvider>
    </div>
  );
}



export default App;
