// import { useState } from "react";
import { dungeonEmpty } from "../../Constants"

export function swapPositions(first, second)  {
     const entry = {}
     entry.name = first.name;
     entry.color = first.color;
     entry.img = first.img

 
     first.name = second.name;
     first.color = second.color;
     first.img = second.img

     second.name = entry.name;
     second.color = entry.color;
     second.img = entry.img;
     
 }
 
export function  blankout(tile) {
     tile.name = dungeonEmpty.name
     tile.color = dungeonEmpty.color
     tile.img = dungeonEmpty.img
 }
const Tile = ({index, tile, dragStart, dragDrop, dragEnd}) => {

     
     

     // const bg = tile.img!==''?  '': tile.color
     const classnames = "shine"

     // const style = "backgroundColor:"+bg 
     if(tile.matched) {
          // console.log(" I am selected")
          return (
               <img key={index} 
               className={classnames}
               src={tile.img}
               alt={tile.name}
               data-id={index}
                                draggable={true}                         
                                onDragStart={dragStart}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={(e) => e.preventDefault()}
                                onDragLeave={(e) => e.preventDefault()}
                                onDrop={dragDrop}
                                onDragEnd={dragEnd}/>   
          ) 
     }

     return (
          <img key={index} 
          
          src={tile.img}
          alt={tile.name}
          data-id={index}
                           draggable={true}                         
                           onDragStart={dragStart}
                           onDragOver={(e) => e.preventDefault()}
                           onDragEnter={(e) => e.preventDefault()}
                           onDragLeave={(e) => e.preventDefault()}
                           onDrop={dragDrop}
                           onDragEnd={dragEnd}/>   
     ) 
     
}

export default Tile